#root {
    /* height: 100%; */
    background: rgb(230, 237, 241);
}

.align-top {
    vertical-align: top;
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 0.25em;
}

.m-4 {
    margin: 1em;
}

.m-8 {
    margin: 2em;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 0.25em;
}

.mb-4 {
    margin-bottom: 1em;
}

.mb-8 {
    margin-bottom: 2em;
}

.mt-4 {
    margin-top: 1em;
}

.mt-8 {
    margin-top: 2em;
}

.mt-12 {
    margin-top: 3em;
}

.mt-16 {
    margin-top: 4em;
}

.text-sm {
    font-size: .875rem;
}

.p-0 {
    padding: 0;
}

.ui.grid > .row.p-0 {
    padding: 0;
}

.ui.grid > .row.p-0.active:nth-child(odd) {
    padding-top: 2px;
}

.ui.grid > .row.p-0.active:nth-child(even) {
    padding-bottom: 2px;
}

.ui.grid > .row:nth-child(even) {
    border-bottom: 1px solid #5c89a3;
}

.ui.grid > .row.p-2 {
    padding: 0.5em;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(50,50,50,0.75);
}

.field.error .Dropdown-control {
    border-color: #9f3a38;
    background-color: #fff6f6;
}

.field.error .Dropdown-placeholder.is-selected {
    color: #9f3a38;
}

.hcenter-vcenter {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.font-bold {
    font-weight: 700;
}

.strip {
    padding-top: 40px;
    padding-bottom: 40px;
}
.strip.white {
    background: white;
}

.footer {
    background-color: #163B30;
    position: relative;
    margin-top: 40px;
}

.footer-text {
    text-align: right;
    color: #f8f8f8;
    letter-spacing: 1.25px;
    font-weight: 100;
}